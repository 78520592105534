import React, { useState } from "react";
import { graphql } from "gatsby";
import classnames from "classnames";

import Layout from "../../components/Layout";
import Header from "../../components/docs/Header";
import { Heading3, Heading4 } from "../../components/docs/Heading";
import VersionNav from "../../components/docs/VersionNav";
import Footer from "../../components/common/Footer";
import Subscribe from "../../components/common/Subscribe";
import Code from "../../components/common/Code";
import ShortCode from "../../components/common/ShortCode";
import SuccessPopup from "../../components/common/SuccessPopup";
import DocMenu from "../../components/docs/DocMenu";
import Feedback from "../../components/common/Feedback";
import Input from "../../components/common/Input";
import InputComponent from "../../components/docs/InputComponent";
import Note from "../../components/docs/Note";

import { useHighlight, useScrollProgress } from "../../utils/hooks";

import * as grid from "../../style/grid.module.css";
import * as css from "../../style/docs.module.css";

const items = [
  {
    name: "Get started",
    children: [
      { name: "Design functions" },
      { name: "Templates" },
      { name: "Examples" }
    ]
  },
  {
    name: "Add a function"
  },
  {
    name: "Add an input",
    children: [
      { name: "Text" },
      { name: "Number" },
      { name: "Color" },
      { name: "Boolean" },
      { name: "Image" },
      { name: "Slider" },
      { name: "Select" }
    ]
  },
  {
    name: "Add a preset"
  },
  {
    name: "Handler arguments"
  },
  {
    name: "Select an engine",
    children: [
      { name: "Canvas" },
      { name: "SVG" },
      { name: "p5.js" },
      { name: "React" }
    ]
  },
  {
    name: "Settings"
  },
  {
    name: "Dive deeper"
  }
];

const DocsPage = ({ data, location }) => {
  const [subscribed, setSubscribed] = useState();
  const progress = useScrollProgress();
  useHighlight();

  const inputs = data.inputs.nodes.sort(
    (a, b) => a.childJson.order - b.childJson.order
  );
  const templates = data.templates.nodes.sort((a, b) =>
    a.childJson.name.localeCompare(b.childJson.name)
  );
  const examples = data.examples.nodes.sort((a, b) =>
    a.childJson.name.localeCompare(b.childJson.name)
  );

  return (
    <Layout title="Mechanic Documentation" className={css.root}>
      <Header
        className={css.header}
        logo={data.logo}
        title={"Documentation"}
        progress={progress}>
        <VersionNav version={location.pathname} />
      </Header>
      <Feedback href="https://forms.gle/uBTn8oVThZHVghV89">
        Got feedback?
      </Feedback>
      <main className={classnames(grid.grid, css.main)}>
        <DocMenu items={items} />
        <div className={classnames(css.docs)}>
          <div className={css.section}>
            <Heading3 id="get-started">Get started</Heading3>
            <p className={css.leftText}>
              Mechanic* is a powerful open source framework that helps
              forward-looking designers move away from a manual design workflow
              by converting their design rules into&nbsp;tools.
              <br />
              <br />
              To start working with Mechanic, you need to create a project. Run
              the following command in the&nbsp;terminal:
            </p>
            <ShortCode copyText="npm init mechanic@latest my-project">
              npm init mechanic@latest my-project
            </ShortCode>
            <Note>
              Make sure you have{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://nodejs.org/en/download/">
                {" "}
                Node.js
              </a>{" "}
              (version 12.20 or greater) and{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://docs.npmjs.com/about-npm-versions">
                npm
              </a>{" "}
              installed.
            </Note>
            <div className={css.leftText}>
              This will install the Mechanic command-line interface and prompt
              you with instructions on how to customize your new Mechanic
              project, including your very first design function. You can choose
              between creating a new design function based on a{" "}
              <a href="#templates">template</a> or a more sophisticated{" "}
              <a href="#examples">example</a>. As a result, you will end up with
              a folder with your chosen project name and the following
              files&nbsp;in it:
              <ul className={css.fileList}>
                <li>
                  <span>
                    <code className={css.highlight}>README.md</code>
                  </span>{" "}
                  contains some pointers on how to start using your
                  Mechanic&nbsp;project{" "}
                </li>
                <li>
                  <span>
                    <code className={css.highlight}>package.json</code>
                  </span>{" "}
                  contains all the dependencies needed to use&nbsp;Mechanic{" "}
                </li>
                <li>
                  <span>
                    <code className={css.highlight}>mechanic.config.js</code>
                  </span>{" "}
                  contains the Mechanic&nbsp;configuration
                </li>
                <li>
                  <span>
                    <code className={css.highlight}>functions/</code>
                  </span>{" "}
                  is a folder that will contain your design&nbsp;functions{" "}
                </li>
              </ul>
            </div>
          </div>
          <div className={css.section}>
            <Heading3 id="design-functions">Design functions</Heading3>
            <p className={css.leftText}>
              Design functions are JavaScript functions that generate a design
              asset. They are the main building block of Mechanic, and a project
              can have one or more design function. Based on the content in the
              design functions, Mechanic creates a design tool you will be able
              to use in your web&nbsp;browser.
              <br />
              <br />
              The generated asset can be either static or animated, simple or
              complex. A design function creates assets using a specific web
              technology and a corresponding Mechanic engine that enables it.
              Currently, the available options for{" "}
              <a href="#select-an-engine">engines</a> are SVG, Canvas, React.js
              and&nbsp;p5.js. <br />
              <br />
              Each design function definition should live in a separate folder
              in the <code className={css.highlight}>functions/</code> folder.
              Each function folder will contain an{" "}
              <code className={css.highlight}>index.js</code> file with the
              following&nbsp;elements:
            </p>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <h4>Main handler</h4>
                <p>
                  This is where you write the code that produces your design,
                  and this code will be specific to the chosen engine. A{" "}
                  <code className={css.highlight}>handler</code> function always
                  receives a single argument, and your code can produce variable
                  designs based on the values in this&nbsp;object.{" "}
                  <a href="#handler-arguments">
                    Learn more about handler arguments
                  </a>
                  .
                </p>
              </div>
              <pre className={css.firstPart}>
                <code>
                  {`export const handler = ({ inputs, mechanic }) => {

  //  Implementation of the design goes here

};`}
                </code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <h4>inputs</h4>
                <p>
                  <code className={css.highlight}>inputs</code> is an object
                  defining the variables that will be passed to your design
                  function. Each input will create an element in the design tool
                  UI, and users can change these inputs and run the function
                  with updated&nbsp;values.{" "}
                  <a href="#add-an-input">Learn more about inputs</a>.
                </p>
              </div>
              <pre className={css.middlePart}>
                <code>
                  {`// Specify as many inputs as you want
export const inputs = {
  input1: {
    type: "number",
    ...
  },
  input2: {
    type: "color",
    ...
  },
  ...
};`}
                </code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <h4>Presets</h4>
                <p>
                  Presets give you the opportunity to set predefined sets of
                  values for the inputs that users can select with a single
                  click in the design tool&nbsp;UI.{" "}
                  <a href="#add-a-preset">Learn more about presets</a>.
                </p>
              </div>
              <pre className={css.middlePart}>
                <code>
                  {`// Specify as many presets as you want
export const presets = {
  preset: {
    input1: 2,
    input2: "#454545",
    ...
  }
};`}
                </code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <h4>Settings</h4>
                <p>
                  Settings allow you to set general configuration for your
                  function. This is where you specify the engine that your
                  design function will be using and whether the design function
                  produces a static or animated&nbsp;asset.{" "}
                  <a href="#settings">Learn more about settings</a>.
                </p>
              </div>
              <pre className={css.lastPart}>
                <code>
                  {`// General configuration for your function
export const settings = {
  engine: require("@mechanic-design/engine_name"),
  animated: false,
  optimize: true,
};`}
                </code>
              </pre>
            </div>
          </div>
          <div className={css.section}>
            <Heading3 id="templates">Templates</Heading3>
            <p className={css.leftText}>
              Templates are simple design functions created to show you how to
              use Mechanic with specific web technologies for either animated or
              static assets. You can use one to get to know Mechanic, or use one
              as a base to start your design&nbsp;function.
            </p>
            <p className={css.leftText}>
              Use the{" "}
              <code className={css.highlight}>npm init mechanic@latest</code>{" "}
              command to create a new Mechanic project with a single design
              function based on a template. Once you have created your Mechanic
              project, you can use the{" "}
              <code className={css.highlight}>npm run new</code> command to add
              more design&nbsp;functions.
            </p>
            <p className={css.leftText}>
              The currently available templates you can find&nbsp;are:
            </p>
            {templates.map((e, key) => (
              <ul key={key} className={css.leftList}>
                <li>
                  <span className={css.listLabel}>{e.childJson.name}</span>:{" "}
                  <span>{e.childJson.description}</span>
                </li>
              </ul>
            ))}
            <p className={css.leftText}>
              Let's take a closer look at the p5.js video template, which
              produces an animated&nbsp;asset.
            </p>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <h4>p5.js video</h4>
                <p>
                  When using the p5.js engine, the handler function argument
                  will have three properties:{" "}
                  <code className={css.highlight}>inputs</code> are the values
                  of the inputs defined below,{" "}
                  <code className={css.highlight}>mechanic</code> holds API
                  functions to record the frames of the animation, and{" "}
                  <code className={css.highlight}>sketch</code> is the p5.js
                  sketch that you can use to invoke p5.js functions. This last
                  argument is specific to this&nbsp;engine.
                </p>
              </div>
              <pre className={css.firstPart}>
                <code>
                  {`export const handler = ({inputs, mechanic, sketch}) => {
  // Extract values of the inputs
  const { width, height, radiusPercentage } = inputs;
  const radius = ((height / 2) * radiusPercentage) / 100;
  let angle = 0;

  // The p5.js draw function is called 60 fps
  sketch.draw = () => {
    sketch.rotate(angle);
    sketch.arc(0, 0, 2 * radius, 2 * radius, -sketch.PI, 0);
    sketch.arc(0, 0, 2 * radius, 2 * radius, 0, sketch.PI);
    sketch.rotate(-angle);
`}
                </code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <p>
                  The <code className={css.highlight}>mechanic.frame()</code>{" "}
                  function is used to record each individual frame of the
                  animation, while the{" "}
                  <code className={css.highlight}>mechanic.done()</code>{" "}
                  function tells Mechanic that the asset has finished rendering.
                  You can read more about these API functions in{" "}
                  <a href="#handler-arguments">handler arguments</a>.
                </p>
              </div>
              <pre className={css.middlePart}>
                <code>
                  {`    if (angle < turns * 2 * Math.PI) {
      mechanic.frame();
      angle += (2 * Math.PI) / 100;
    } else {
      mechanic.done();
    }
  };
};

export const inputs = {
  width: {
    type: "number",
    default: 400,
  },
  height: {
    type: "number",
    default: 300,
  },
  radiusPercentage: {
    type: "number",
    default: 40,
    min: 0,
    max: 100,
    slider: true,
  },
  // ...
};

export const presets = {
  medium: {
    width: 800,
    height: 600,
  },
  // ...
};
`}
                </code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <p>
                  The settings define that this design function uses the p5.js
                  engine via the{" "}
                  <code className={css.highlight}>
                    @mechanic-design/engine-p5
                  </code>{" "}
                  package. Also, since this asset is animated and uses the{" "}
                  <code className={css.highlight}>frame()</code> method, this is
                  specified in the settings&nbsp;too.
                </p>
              </div>
              <pre className={css.lastPart}>
                <code>
                  {`export const settings = {
  engine: require("@mechanic-design/engine-p5"),
  animated: true,
};`}
                </code>
              </pre>
            </div>
          </div>
          <div className={css.section}>
            <Heading3 id="examples">Examples</Heading3>
            <p className={css.leftText}>
              Examples are more complex design functions created to show you how
              to use Mechanic to tackle some more advanced use cases. Currently,
              Mechanic has three examples, and more may be added along
              the&nbsp;way.
            </p>

            <p className={css.leftText}>
              Use the{" "}
              <code className={css.highlight}>npm init mechanic@latest</code>{" "}
              command to create a new Mechanic project with a single design
              function based on an example. Once you have created your Mechanic
              project, you can use the{" "}
              <code className={css.highlight}>npm run new</code> command to add
              more design&nbsp;functions.
            </p>
            {examples.map((e, key) => (
              <ul key={key} className={css.leftList}>
                <li>
                  <h4>{e.childJson.name}</h4>
                  <p className={css.listParagraph}>{e.childJson.description}</p>
                </li>
              </ul>
            ))}
          </div>
          <div className={css.section}>
            <Heading3 id="add-a-function">Add a function</Heading3>
            <p className={css.leftText}>
              You can always add more design functions to a Mechanic project. To
              do so, run the following command in the terminal in your mechanic
              project folder. This will create a new folder in your Mechanic
              project with an <code className={css.highlight}>index.js</code>{" "}
              file based on a template or an&nbsp;example.
            </p>
            <ShortCode copyText="npm run new">npm run new</ShortCode>
            <p className={classnames(css.leftText, css.lower)}>
              Alternatively, you can create the corresponding folders and files
              manually to add a new design function. Make sure you follow a
              similar folder structure as the one mentioned and that you add the
              necessary dependencies to you project to make it&nbsp;work.
            </p>
          </div>
          <div className={css.section}>
            <Heading3 id="add-an-input">Add an input</Heading3>
            <p className={css.leftText}>
              <code className={css.highlight}>inputs</code> is an object
              defining the variables that will be passed to your design
              function. Each input will create an element in the design tool UI,
              and users can change these inputs and run the function with
              updated values. There are many types of inputs and each of them
              have different settings that change their behavior. Once defined,
              the input will be accessible in the handler function via the name
              provided in the <code className={css.highlight}>inputs</code>{" "}
              object.
            </p>
            <div className={css.twoColumns}>
              <div className={css.code}>
                <span> Definition </span>
                <Code variant="blue" className={css.codeColumn}>
                  {`const inputs = {
  name: {
    type: input_type,
    default: value,
    validation: validation_function,
    editable: editable_function ,
    label: label_string
  }
};`}
                </Code>
              </div>
              <div className={css.code}>
                <span> Use in handler </span>
                <Code variant="blue" className={css.codeColumn}>
                  {`export const handler = ({ inputs, mechanic }) => {
  const { name } = inputs;
  // Use "name" in your code...
};`}
                </Code>
              </div>
            </div>
            <p className={css.leftText}>
              The following properties are shared among all inputs.
            </p>
            <ul className={css.inputProperties}>
              <li>
                <span>
                  <code className={css.highlight}>type</code>
                </span>
                : string value that determines the type of variable (
                <code className={css.highlight}>"number"</code>,{" "}
                <code className={css.highlight}>"text"</code>,{" "}
                <code className={css.highlight}>"color"</code>,{" "}
                <code className={css.highlight}>"boolean"</code>,{" "}
                <code className={css.highlight}>"image"</code>). It determines
                the type of object that the handler will receive as a value and
                how it looks in the UI. This property is&nbsp;required.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>default</code>
                </span>
                : value that acts as a fallback for input if not yet set by the
                user. This property is required for most types of inputs, except
                for <code className={css.highlight}>"boolean"</code> and{" "}
                <code className={css.highlight}>"image"</code>.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>validation</code>
                </span>
                : function that receives the current value assigned to the input
                and outputs an error (as a string) when the value entered
                doesn't pass a certain condition. When the given function
                returns an error string, the error is shown in the UI. If the
                value passes conditions (no error) a{" "}
                <code className={css.highlight}>null</code> value
                is&nbsp;expected.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>editable</code>
                </span>
                : function that receives all current values assigned to the
                defined inputs and determines if the input can be edited or not.
                A boolean value is expected as an output of the
                given&nbsp;function.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>label</code>
                </span>
                : string value to use as label for input in UI input. If not
                set, by default the input name is used as the label for
                the&nbsp;UI.
              </li>
            </ul>
            {inputs.map((e, key) => (
              <ul key={key} className={css.inputs}>
                <li>
                  <Input data={e.childJson} />
                </li>
              </ul>
            ))}
          </div>
          <div className={css.section}>
            <Heading3 id="add-a-preset">Add a preset</Heading3>
            <p className={css.leftText}>
              Presets give you the opportunity to set predefined sets of values
              for the inputs that users can select with a single click in the
              design tool UI. You can have multiple presets per function, which
              allow the user to quickly select a number of values in a
              single&nbsp;action.
            </p>
            <div className={css.presetCode}>
              <div className={css.code}>
                <Code variant="blue" className={css.codeColumn}>
                  {`export const presets = {
  presetName1: {
    input1: value,
    input2: value,
    // ...
  },
  presetName2: {
    input1: value,
    input2: value,
    // ...
  },
};`}
                </Code>
              </div>
              <InputComponent
                className={css.presetInput}
                name="Presets"
                attributes={{
                  type: "text",
                  options: [
                    "no presets",
                    "presetName1",
                    "presetName2",
                    "default values"
                  ],
                  default: "no presets"
                }}
              />
            </div>
          </div>
          <div className={css.section}>
            <Heading3 id="handler-arguments">Handler arguments</Heading3>
            <p className={css.leftText}>
              The handler function of a design function always receives a single
              argument with at least two properties that are key to the
              function's rendering:{" "}
              <code className={css.highlight}>inputs</code> and{" "}
              <code className={css.highlight}>mechanic</code>. Depending on the{" "}
              engine, the handler function may receive more properties in the
              object&nbsp;argument.{" "}
              <a href="#select-an-engine">
                Read more about the individual engines
              </a>
              .
            </p>
            <ul className={css.handlerArguments}>
              <li>
                <span>
                  <code className={css.highlight}>inputs</code>:&nbsp;
                </span>
                The <code className={css.highlight}>inputs</code> property will
                contain all values selected through the UI interface for each
                defined input. Your design function can use these values to
                change what is being&nbsp;rendered.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>mechanic</code>:&nbsp;
                </span>
                The <code className={css.highlight}>mechanic</code> property
                serves as a proxy to Mechanic’s core functionality, wrapping two
                important methods:{" "}
                <code className={css.highlight}>mechanic.done()</code> and{" "}
                <code className={css.highlight}>mechanic.frame()</code>. The{" "}
                <code className={css.highlight}>done()</code> method tells
                Mechanic that the code to generate an asset has finished. This
                function must be called at some point in the handler's code for
                both static and animated assets. For animated assets, the{" "}
                <code className={css.highlight}>frame()</code> method adds
                separate frames of the asset and is expected to be called before
                the call to&nbsp;<code className={css.highlight}>done()</code>.
              </li>
            </ul>
          </div>
          <div className={css.section}>
            <Heading3 id="select-an-engine">Select an engine</Heading3>
            <p className={classnames(css.leftText, css.engineText)}>
              Engines are JavaScript functions that enable certain web
              technologies with Mechanic’s environment. Currently there are four
              official engines published. It is unlikely you will need to write
              an engine from scratch, but the offical engines should be a good
              guide in case you want to enable a non-supported JavaScript
              library to work with&nbsp;Mechanic.
            </p>
            <Heading4 id="canvas">Canvas</Heading4>
            <p className={classnames(css.leftText, css.engineText)}>
              This engine enables the use of the{" "}
              <a href="https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API">
                Canvas API
              </a>{" "}
              in the Mechanic framework. While using this engine, both the{" "}
              <code className={css.highlight}>frame()</code> and the{" "}
              <code className={css.highlight}>done()</code> method expect to be
              called with a single argument: the canvas object that the design
              function is using to render the&nbsp;asset.
            </p>
            <div className={css.twoColumns}>
              <div className={css.code}>
                <span> Definition </span>
                <Code variant="blue" className={css.codeColumn}>
                  {`const settings = {
  engine: require("@mechanic-design/engine-canvas"),
};`}
                </Code>
              </div>
              <div className={css.code}>
                <span> Use in handler </span>
                <Code variant="blue" className={css.codeColumn}>
                  {`export const handler = ({ inputs, mechanic }) => {
  const { width, height, color } = inputs;
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext("2d");
  ctx.fillStyle = color;
  // ...
  mechanic.done(canvas)
};`}
                </Code>
              </div>
            </div>

            <Heading4 id="svg" className={css.engineTitle}>
              SVG
            </Heading4>
            <p className={classnames(css.leftText, css.engineText)}>
              This engine enables the use of{" "}
              <a href="https://developer.mozilla.org/en-US/docs/Web/SVG">
                SVG strings
              </a>{" "}
              in the Mechanic framework. While using this engine, both methods
              of the <code className={css.highlight}>mechanic</code> handler
              parameter are expected to be called with a single argument: the
              string value of the SVG&nbsp;image.
            </p>
            <div className={css.twoColumns}>
              <div className={css.code}>
                <span> Definition </span>
                <Code variant="blue" className={css.codeColumn}>
                  {`const settings = {
  engine: require("@mechanic-design/engine-svg"),
};`}
                </Code>
              </div>
              <div className={css.code}>
                <span> Use in handler </span>
                <Code variant="blue" className={css.codeColumn}>
                  {`export const handler = ({ inputs, mechanic }) => {
  const { width, height, color } = inputs;
  const svg = \`<svg width="\${width}" height="\${height}">
      <rect fill="\${color}" width="\${width}" height="\${height}" />
    </svg>\`

  mechanic.done(svg);
};`}
                </Code>
              </div>
            </div>

            <Heading4 id="p5.js" className={css.engineTitle}>
              p5.js
            </Heading4>
            <p className={classnames(css.leftText, css.engineText)}>
              This engine enables the use of{" "}
              <a href="https://p5js.org/">p5.js</a> in the Mechanic framework.
            </p>
            <div className={css.twoColumns}>
              <div className={css.code}>
                <span> Definition </span>
                <Code variant="blue" className={css.codeColumn}>
                  {`const settings = {
  engine: require("@mechanic-design/engine-p5"),
};`}
                </Code>
              </div>
              <div className={css.code}>
                <span> Use in handler </span>
                <Code variant="blue" className={css.codeColumn}>
                  {`export const handler = ({ inputs, mechanic, sketch }) => {
  const { width, height, color } = inputs;
  sketch.setup = () => {
    sketch.createCanvas(width, height);
  };

  sketch.draw = () => {
    sketch.background(color);
    mechanic.done();
  };
};`}
                </Code>
              </div>
            </div>

            <Heading4 id="react" className={css.engineTitle}>
              React
            </Heading4>
            <p className={classnames(css.leftText, css.engineText)}>
              This engine enables the use of the{" "}
              <a href="https://reactjs.org/">React SVG components</a> in the
              Mechanic framework.
            </p>
            <div className={css.twoColumns}>
              <div className={css.code}>
                <span> Definition </span>
                <Code variant="blue" className={css.codeColumn}>
                  {`const settings = {
  engine: require("@mechanic-design/engine-react"),
};`}
                </Code>
              </div>
              <div className={css.code}>
                <span> Use in handler </span>
                <Code variant="blue" className={css.codeColumn}>
                  {`export const handler = ({ inputs, mechanic }) => {
  const { width, height, color } = inputs;
  useEffect(() => {
    mechanic.done();
  }, []);

  return (
    <svg width={width} height={height}>
      <rect fill={color} width={width} height={height} />
    </svg>
  );
};`}
                </Code>
              </div>
            </div>
          </div>
          <div className={css.section}>
            <Heading3 id="settings">Settings</Heading3>
            <p className={css.leftText}>
              Settings allow you to set general configuration for your design
              function. The following are the possible properties that are
              available to be&nbsp;set.
            </p>
            <ul className={css.inputProperties}>
              <li>
                <span>
                  <code className={css.highlight}>engine</code>
                </span>
                : sets the engine for the design function. This property always
                has to be&nbsp;set.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>name</code>
                </span>
                : Set the display name for the design function in the&nbsp;UI.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>animated</code>
                </span>
                : determines whether the design function is an animated sequence
                or a static image. Defaults to{" "}
                <code className={css.highlight}>false</code>.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>persistRandomOnExport</code>
                </span>
                : if {"  "}
                <code className={css.highlight}>true</code>, enables a seeded
                random that forces the export to generate the same output as the
                last preview. Defaults to{" "}
                <code className={css.highlight}>true</code>.
              </li>

              <li>
                <span>
                  <code className={css.highlight}>optimize</code>
                </span>
                : optimizes SVG outputs using{" "}
                <a href="https://github.com/svg/svgo">SVGO</a>. If an object is
                received, it is merged with the default SVGO options and passed
                to the{" "}
                <a href="https://github.com/svg/svgo#optimize">
                  optimize function
                </a>
                . Defaults to <code className={css.highlight}>true</code>.
              </li>
            </ul>
          </div>
          <div className={css.section}>
            <Heading3 id="dive-deeper">Finally</Heading3>
            <p className={css.leftText}>
              If you want to dive deeper into Mechanic we suggest checking out
              the{" "}
              <a
                href="https://github.com/designsystemsinternational/mechanic"
                target="_blank"
                rel="noreferrer">
                Github page
              </a>
              .
            </p>
            <p className={css.leftText}>
              We are very interested in hearing feedback from you, so{" "}
              <a href="https://forms.gle/uBTn8oVThZHVghV89">
                please let us know what you&nbsp;think
              </a>
              .
            </p>
          </div>
        </div>
      </main>
      <div className={grid.grid}>
        <Subscribe
          className={classnames(css.subscribe, grid.col)}
          variant={"blue"}
        />
      </div>
      <Footer subscribed={subscribed} variant={"blue"} />
      <SuccessPopup setSubscribed={setSubscribed} />
    </Layout>
  );
};

export default DocsPage;

export const query = graphql`
  query {
    examples: allFile(
      filter: {
        extension: { eq: "json" }
        sourceInstanceName: { eq: "examples" }
        relativeDirectory: { eq: "v1.2.0" }
      }
    ) {
      nodes {
        childJson {
          name
          description
        }
      }
    }
    inputs: allFile(
      filter: {
        extension: { eq: "json" }
        sourceInstanceName: { eq: "inputs" }
        relativeDirectory: { eq: "v1.2.0" }
      }
    ) {
      nodes {
        childJson {
          order
          name
          title
          description
          code
          attributes
        }
      }
    }
    templates: allFile(
      filter: {
        extension: { eq: "json" }
        sourceInstanceName: { eq: "templates" }
        relativeDirectory: { eq: "v1.2.0" }
      }
    ) {
      nodes {
        childJson {
          name
          description
        }
      }
    }
    logo: file(name: { eq: "logo" }) {
      childImageSharp {
        gatsbyImageData(width: 30)
      }
    }
  }
`;
