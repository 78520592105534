import React from "react";
import * as css from "./Input.module.css";

import Code from "./Code";
import { Heading4 } from "../docs/Heading";
import InputComponent from "../docs/InputComponent";

import { widont } from "../../utils/index.js";

export const Input = ({ data }) => {
  const { name, title, description, code, attributes } = data;
  return (
    <div className={css.root}>
      <Heading4 id={name.toLowerCase().replace(/\s/g, "-")}>{name}</Heading4>
      <p className={css.leftText}>{widont(description)}</p>
      <div className={css.details}>
        <Code className={css.codeColumn} variant="blue">
          {code}
        </Code>
        <InputComponent
          className={css.column}
          name={title}
          attributes={JSON.parse(attributes)}
        />
      </div>
    </div>
  );
};

export default Input;
